import type { answersI } from '../contexts/answersContext'
import type { msgsI, msgsAction } from '../contexts/errorsContext'
import { compose } from 'redux'

/* email: 先頭が英数、次は英数記号（-_.）が任意回数、'@'、次は英数、次は英数記号（-_.）が任意回数、末尾は'.'と英数2-3文字 */
const emailRegexp = /^[\w]([-_.]?[\w])*@[\w]([-_.]?[\w])*\.[a-zA-Z]{2,3}$/

const numberRegexp = /^[0-9]+$/
const blankRegexp = /\s/g // 空白・改行の数＝入力文字数となる場合はエラーのため、"/g"オプション必須
const hyphenList = [
  '―', 'ー', '‐', '₋', '―', '―', '⁻', '₋', '-', '−', '➖', '–', 'ｰ', '－'
]

export const invalidMsgs: {
  require: string
  number: string
  phone: msgsI['phone']
  email: msgsI['email']
  button: msgsI['button']
} = {
  require: '必須項目です',
  number: '半角数字のみで入力してください',
  phone: '不正な電話番号です',
  email: '不正なメールアドレスです',
  button: 'エラー項目があります'
}
type invalidMsgsTypes = keyof typeof invalidMsgs

/* 結果： エラーあり → false / エラーなし → true */
// require : 有効値があるか
export const hasValues = (target?: string): boolean => {
  if (target !== undefined) {
    return (target.length === 0)
      ? false
      : (!blankRegexp.test(target) && (target.match(blankRegexp)?.length !== target.length))
  } return false
}
// require : 有効値があるか
export const hasValueForTextArea = (target?: string): boolean => {
  if (target !== undefined) {
    return (target.length === 0)
      ? false
      : (target.match(blankRegexp)?.length !== target.length)
  } return false
}
// number : 入力された値が数字のみか
export const isNumber = (target?: string): boolean => {
  if (target !== undefined) {
    return ((target.length > 0 && numberRegexp.test(target)) || target.length === 0)
  } return false
}
// phone : 数字のみ、かつ10-11文字か
export const isPhone = (target?: string): boolean => {
  if (target !== undefined) {
    const length: number = target.length
    const maxN = 11
    const minN = 10
    return ((length <= maxN) && (length >= minN) && numberRegexp.test(target))
  } return false
}
// email : 正しい形式か
export const isEmail = (target?: string): boolean => {
  if (target !== undefined) {
    const length: number = target.length
    const maxN = 255
    return ((length <= maxN) && emailRegexp.test(target))
  } return false
}

/* バリデーション実施メソッド（allValidation用） */
export const checker = (checker: boolean, key: invalidMsgsTypes, target?: string, text?: string): [string?, string?] => {
  if (text !== undefined) {
    return [target, text]
  } else if (target === undefined) {
    return [target, invalidMsgs[key]]
  } else {
    return (!checker)
      ? [target, invalidMsgs[key]]
      : [target]
  }
}
export const tryHasValues = ([target, text]: [string?, string?]): [string?, string?] => {
  return checker(hasValues(target), 'require', target, text)
}
export const tryHasValueForTextArea = ([target, text]: [string?, string?]): [string?, string?] => {
  return checker(hasValueForTextArea(target), 'require', target, text)
}
export const tryIsNumber = ([target, text]: [string?, string?]): [string?, string?] => {
  return checker(isNumber(target), 'number', target, text)
}
export const tryIsPhone = ([target, text]: [string?, string?]): [string?, string?] => {
  return checker(isPhone(target), 'phone', target, text)
}
export const tryIsEmail = ([target, text]: [string?, string?]): [string?, string?] => {
  return checker(isEmail(target), 'email', target, text)
}

/* ハイフン置換、半角変換 */
const toPhoneNum = (target: string): string => {
  let phoneNum = target
  // ハイフン置換
  hyphenList.forEach((hyphen) => {
    phoneNum = phoneNum.replaceAll(hyphen, '')
  })
  // 半角変換
  const getSmaller = (text: string): string => {
    return String.fromCharCode(text.charCodeAt(0) - 0xFEE0)
  }
  phoneNum = phoneNum.replace(/[０-９]/g, getSmaller)
  return phoneNum
}

/*
ボタン押下時の処理
・エラーメッセージの配列を生成 -> チェック結果がfalseのとき、各メッセージを出す
・エラーが１つでもあればbuttonのエラーメッセージを出す
*/
const allValidations = (answers: answersI): [boolean, msgsAction[]] => {
  const msgActions: msgsAction[] = []
  Object.entries(answers).forEach(([key, value]: [string, string]) => {
    if (key === 'name' || key === 'organization' || key === 'email' || key === 'phone' || key === 'category' || key === 'content') {
      const action: keyof answersI = key
      const setMessage = ([, text]: [string?, string?]): void => {
        if (text !== undefined) {
          msgActions.push({ type: key, message: text })
        }
      }
      switch (action) {
        case 'name' : {
          const errorCheck = tryHasValues([value])
          setMessage(errorCheck)
        }
          break
        case 'organization' : {
          const errorCheck = tryHasValues([value])
          setMessage(errorCheck)
        }
          break
        case 'email' : {
          const errorCheck = compose(tryIsEmail, tryHasValues)([value])
          setMessage(errorCheck)
        }
          break
        case 'phone' : {
          const errorCheck = compose(tryIsPhone, tryIsNumber, tryHasValues)([value])
          setMessage(errorCheck)
        }
          break
        case 'category' : {
          const errorCheck = tryHasValues([value])
          setMessage(errorCheck)
        }
          break
        case 'content' : {
          const errorCheck = tryHasValueForTextArea([value])
          setMessage(errorCheck)
        }
          break
      }
    }
  })
  // 一行で書き直す！
  let isError = false // エラー有無チェッカー
  /* actionsにエラーメッセージが格納されている場合、ボタン用のエラーメッセージを格納 */
  if (msgActions.some((obj) => obj.message.length > 0)) {
    isError = true
    msgActions.push({ type: 'button', message: invalidMsgs.button })
  }
  return [isError, msgActions]
}

export { toPhoneNum, allValidations }
